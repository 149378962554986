@@ -1,76 +0,0 @@
<template>
  <v-card class="pa-8">
    <h1 class="text-center">จ่ายค่าคอมมิชชัน</h1>
    <h3>ข้อมูลผู้ใช้งาน</h3>
    <v-row class="text-end">
      <v-col cols="2"
        ><p class="pt-2"><b>รหัสตัวแทน:</b></p></v-col
      >
      <v-col cols="4">
        <span class="left pt-2">{{ agentNo }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <!-- <v-col cols="2"><p class="pt-2">อนุมัติตัวแทน</p></v-col>
      <v-col cols="4">
        <v-switch v-model="activeFlag" class="mt-1"></v-switch>
      </v-col> -->
    </v-row>
    <v-row class="text-end">
      <v-col cols="2"
        ><p class="pt-2"><b>ชื่อจริง:</b></p></v-col
      >
      <v-col cols="4">
        <span class="left pt-2">{{ firstName }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"
        ><p class="pt-2"><b>นามสกุล:</b></p></v-col
      >
      <v-col cols="4">
        <span class="left pt-2">{{ lastName }}</span>
      </v-col>
    </v-row>
    <v-row class="text-end">
      <v-col cols="2"
        ><p class="pt-2"><b>อีเมล:</b></p></v-col
      >
      <v-col cols="4">
        <span class="left pt-2">{{ email }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"
        ><p class="pt-2"><b>หมายเลขโทรศัพท์:</b></p></v-col
      >
      <v-col cols="4">
        <span class="left pt-2">{{ tel }}</span>
      </v-col>
    </v-row>
    <v-divider class="mb-6"></v-divider>
    <v-row class="text-end">
      <v-col cols="2"
        ><p class="pt-2"><b>ธนาคาร:</b></p></v-col
      >
      <v-col cols="4">
        <span class="left pt-2">{{ bankName }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"
        ><p class="pt-2"><b>หมายเลขบัญชีธนาคาร:</b></p></v-col
      >
      <v-col cols="4">
        <span class="left pt-2">{{ bankNo }}</span>
      </v-col>
      <v-col cols="2"
        ><p class="pt-2"><b>VIP:</b></p></v-col
      >
      <v-col cols="4">
        <span class="left pt-2">{{ member }}</span>
      </v-col>
    </v-row>
    <v-divider class="mb-6"></v-divider>
    <v-row align="center" justify="center">
      <h1 style="color: red">ยอดคอมมิชชันคงเหลือ: {{ comPrice }} บาท</h1>
    </v-row>
    <v-row>
      <!-- <v-col cols="4"
        ><p class="pt-2">จำนวนเงินที่ต้องการจ่ายค่าคอมมิชชัน:</p></v-col
      >
      <v-col cols="6">
        <v-text-field
          v-model="comPrice"
          suffix="บาท"
          outlined
          dense
        ></v-text-field>
      </v-col> -->
      <v-col cols="4"
        ><p class="pt-2">เลือกงวดที่ต้องการจ่ายค่าคอมมิชชัน:</p></v-col
      >
      <v-col cols="6">
        <v-menu
          v-model="menufrom"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="comDate"
              label="งวดที่ต้องการจ่ายค่าคอมมิชชัน"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              solo
              clearable
              @click:clear="comDate = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="comDate"
            @input="menufrom = false"
            @click="$refs.menufrom.save(comDate)"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6">
        <span>อัพโหลดสลิป</span>
        <v-img :src="showImage1" width="200px"></v-img>
        <v-text-field
          v-model="picture_1"
          @click="changePic1()"
          :placeholder="imageName1"
          prepend-inner-icon="mdi-camera"
          readonly
          outlined
          dense
        >
        </v-text-field
        ><input
          type="file"
          ref="image"
          id="picTure1"
          accept="image/*"
          @change="showPicture1($event)"
          style="display: none"
        />
      </v-col>
      <v-col cols="12" md="6">
        <span>ใบกำกับภาษีหัก ณ ที่จ่าย</span>
        <v-img :src="showImage2" width="200px"></v-img>
        <v-text-field
          v-model="picture_2"
          @click="changePic2()"
          :placeholder="imageName2"
          prepend-inner-icon="mdi-camera"
          readonly
          outlined
          dense
        >
        </v-text-field
        ><input
          type="file"
          ref="image"
          id="picTure2"
          accept="image/*"
          @change="showPicture2($event)"
          style="display: none"
        />
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="4"><p class="pt-2">รูปบัตรประจำตัวประชาชน</p></v-col>
      <v-img :src="idCardImgUrl"></v-img>
    </v-row>
    <v-row>
      <v-col cols="4"><p class="pt-2">รูปถ่ายคู่บัตรประชาชน</p></v-col>
      <v-img :src="idCardWithPersonImgUrl"></v-img>
    </v-row> -->

    <v-row>
      <v-btn @click="cancel()">กลับ</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="submit()" color="primary">ตกลง</v-btn>
    </v-row>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      tel: "",
      activeFlag: false,
      agentNo: "",
      bankName: "",
      bankNo: "",
      idCardImgUrl: "",
      idCardWithPersonImgUrl: "",
      member: "",
      memberItems: ["ปกติ", "VIP", "VIP+"],
      comPrice: 0,
      comDate: "",
      menufrom: false,
      picture_1: "",
      imageName1: "",
      pic1: "",
      showImage1: "",
      picture_2: "",
      imageName2: "",
      pic2: "",
      showImage2: "",
      // comDate: {
      //       type: Sequelize.DATEONLY,
      //   },
      //   comPrice: {
      //       type: Sequelize.DOUBLE,
      //   },
      //   comSlipUrl: {
      //       type: Sequelize.STRING,
      //       defalutValue: '',
      //   },
      //   comPNDUrl: {
      //       type: Sequelize.STRING,
      //       defalutValue: '',
      //   },
      //   adminId: {
      //       type: Sequelize.INTEGER,
      //   },
    };
  },
  created() {
    var userdata = JSON.parse(
      Decode.decode(localStorage.getItem("dealerdata"))
    );
    console.log("userdata", userdata);
    this.id = userdata.id;
    this.firstName = userdata.firstName;
    this.lastName = userdata.lastName;
    this.email = userdata.email;
    this.tel = userdata.tel;
    this.activeFlag = userdata.activeFlag;
    this.agentNo = userdata.agentNo;
    this.bankName = userdata.bankName;
    this.bankNo = userdata.bankNo;
    this.member = userdata.member;
    this.idCardImgUrl = userdata.idCardImgUrl;
    this.idCardWithPersonImgUrl = userdata.idCardWithPersonImgUrl;
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage1 = URL.createObjectURL(element);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    showPicture2(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic2 = reader.result;
          this.showImage2 = URL.createObjectURL(element);
        };
      }
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    cancel() {
      this.$router.push("ManageDealer");
    },
    async submit() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      console.log("userdata", user);
      const data = {
        // id: this.id,
        comPrice: this.comPrice,
        comDate: this.comDate,
        comSlipUrl: this.pic1,
        comPNDUrl: this.pic2,
        adminId: user.user.id,
        agentId: this.id,
      };
      this.$swal({
        text: "คุณต้องการยืนยันบันทึกข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยันข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // var user = JSON.parse(
          //   Decode.decode(localStorage.getItem("DDLotAdmin"))
          // );
          // const auth = {
          //   headers: { Authorization: `Bearer ${user.token}` },
          // };
          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/agents/createCom`,
            data,
            auth
          );
          console.log(response);

          if (response.data.response_status == "SUCCESS") {
            this.$swal.fire({
              icon: "success",
              text: `บันทึกข้อมูลสำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            });
            // await location.reload();
            this.$router.push("ManageDealer");
          }
        }
      });
      // const response = await this.axios.put(
      //   `${process.env.VUE_APP_API}/agents/` + this.id,
      //   data,
      //   auth
      // );
      // console.log("createUser", response);
      // console.log(response.data.response_status);
      // if (response.data.response_status == "SUCCESS") {
      //   this.$swal.fire({
      //     icon: "success",
      //     text: `แก้ไขข้อมูลสำเร็จ`,
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      //   this.$router.push("ManageDealer");
      // } else {
      //   this.$swal.fire({
      //     icon: "error",
      //     text: `แก้ไขข้อมูลไม่สำเร็จ`,
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      // }
    },
  },
};
</script>
